






























































import Vue from 'vue'
import { mapState } from 'vuex'
import { Sale, Requisition, ApiResponse, Project } from '../../interfaces'
import SignedIcon from '@/components/Icons/SignedIcon.vue'

interface Badge {
  color: string
  value: string
}

interface DropdownAction {
  action: string
  requisition?: Requisition
  project?: Project
}

export default Vue.extend({
  name: 'RequisitionsTable',
  components: {
    SignedIcon,
  },
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      requisitions: [] as Requisition[],
      stats: {
        count: [],
        signed: [],
      },
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    sale(newVal) {
      if (Number.isInteger(newVal.id) && this.activeTab === 'requisitions') {
        this.getRequisitions()
      }
    },
    activeTab(newVal) {
      if (newVal === 'requisitions' && this.sale.id) {
        this.getRequisitions()
      }
    },
  },
  mounted() {
    if (this.sale && Number.isInteger(this.sale?.id) && this.activeTab === 'requisitions') {
      this.getRequisitions()
    }
    this.addons = this.user.client?.addons ?? 0
  },
  methods: {
    getRequisitions() {
      if (!this.busy && this.requisitions.length === 0) {
        this.busy = true
        const id = this.sale?.id

        this.$api
          .get(`/sale/${id}/requisitions`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.requisitions = apiResponse.data.requisitions
            this.stats = apiResponse.data.stats
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    goToRequisition(row: Project, column: Record<string, string>, cell: HTMLTableCellElement): void {
      if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: row.id?.toString() ?? '',
            requisitionId: row.requisition?.id?.toString() ?? '',
          },
          query: {
            sale: this.sale?.id?.toString() ?? '',
          },
        })
      }
    },
    getStructureType(project: Project) {
      return project.structure?.type ?? null
    },
    getStructureName(project: Project): string {
      return project.structure?.name ?? '-'
    },
    // getDoneItems(project: Project): string {
    //   let totalItems = 0
    //   let doneItems = 0

    //   if (project.requisition?.requisitionItems && project.requisition?.requisitionItems?.length > 0) {
    //     project.requisition.requisitionItems.forEach((item) => {
    //       if (item.sale?.id === this.sale.id) {
    //         if (
    //           item.stockStatus == 'given_back' ||
    //           item.stockStatus == 'to_buyer_back' ||
    //           item.stockStatus == 'regrouped' ||
    //           item.stockStatus == 'stolen'
    //         ) {
    //           doneItems++
    //         }
    //         totalItems++
    //       }
    //     })
    //   }

    //   return `${doneItems} / ${totalItems}`
    // },
    getNbItemsInThisSale(project: Project): string {
      let totalItems = 0

      let id = project.requisition?.id

      if (id) {
        totalItems = this.stats.count[id]
      } else {
        totalItems = 0
      }
      return `${totalItems}`
    },
    getNbSigned(project: Project): string {
      let total = 0

      let id = project.requisition?.id

      if (id) {
        total = this.stats.signed[id]
      } else {
        total = 0
      }
      return `${total}`
    },
    getIban(project: Project): string {
      let iban = project.requisition?.requerant?.iban ?? null

      if (iban) {
        return 'success'
      }
      return 'danger'
    },
    handleRequisitionCommand(command: DropdownAction) {
      // Edit requisition
      if (command.action == 'edit' && command.project) {
        this.editRequisition(command.project)
      }

      // Delete requisition
      if (command.action == 'delete' && command.project) {
        this.deleteProject(command.project)
      }
    },
    editRequisition(project: Project) {
      if (project.id) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: project.id?.toString() ?? '',
            requisitionId: project.requisition?.id?.toString() ?? '',
          },
        })
      }
    },
    deleteProject(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette réquisition ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.getRequisitions()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    getStructureColor(project: Project) {
      return project.structure?.color
    },
  },
})
