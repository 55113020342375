














































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, Contact, Sale } from '@/interfaces'

interface SaleObject {
  id?: number | null
  title?: string | null
  place?: string | null
  collectArea?: string | null
  startDay?: string | null
  startTime?: string | null
  endTime?: string | null
  notes?: string | null
  auctioneerId?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  description?: string | null
  terms?: string | null
  drouotURL?: string | null
  interencheresURL?: string | null
  status?: string | null
}

export default Vue.extend({
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: function () {
        return {}
      },
    },
    isDisabled: Boolean,
    saveSaleTrigger: Boolean,
  },
  data() {
    return {
      addons: 0,
      busy: false,
      formModel: {
        id: null,
        title: null,
        place: null,
        collectArea: null,
        startDay: null,
        startTime: null,
        endTime: null,
        notes: null,
        auctioneerId: null,
        description: null,
        terms: null,
        drouotURL: null,
        interencheresURL: null,
        status: 'en_preparation',
      } as SaleObject,
      formRules: {},
      auctioneers: [] as Record<string, string | null>[],
      viewportWidth: 0,
      changeDetected: null as null | boolean,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    sale(newVal) {
      this.setFormModel(newVal)
      this.changeDetected = null
    },
    formModel: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.changeDetected === null) {
          this.changeDetected = false
        } else {
          this.changeDetected = true
          this.$emit('updateTitle', newVal.title)
        }
      },
    },
    saveSaleTrigger() {
      this.submit()
    },
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)

    this.addons = this.user.client?.addons ?? 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    goTo(elementId: string) {
      document.getElementById(elementId)?.click()
    },
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    setFormModel(sale: Sale) {
      this.formModel = {
        title: sale.title ?? null,
        place: sale.place ?? null,
        collectArea: sale.collectArea ?? null,
        startDay: sale.startDate ? this.getDate(sale.startDate) : null,
        startTime: sale.startDate ? this.getHour(sale.startDate) : null,
        endTime: sale.endDate ? this.getHour(sale.endDate) : null,
        notes: sale.notes ?? null,
        auctioneerId: sale.auctioneer?.id?.toString() ?? null,
        createdAt: sale.createdAt ?? null,
        updatedAt: sale.updatedAt ?? null,
        description: sale.description ?? null,
        terms: sale.terms ?? null,
        drouotURL: sale.drouotURL ?? null,
        interencheresURL: sale.interencheresURL ?? null,
        status: sale.status ?? 'en_preparation',
      }

      // Auctioneers
      if (sale.auctioneer?.id) {
        this.auctioneers = [
          {
            label: sale.auctioneer?.formName ?? '-',
            value: sale.auctioneer?.id?.toString() ?? null,
          },
        ]
      }
    },
    getDate(str: string): string | null {
      const ms = Date.parse(str)
      if (!isNaN(ms)) {
        const date = new Date(ms)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      } else {
        return null
      }
    },
    getHour(str: string): string | null {
      const ms = Date.parse(str)

      if (!isNaN(ms)) {
        const a = str.split('T')
        const z = a[1].split('+')
        const t = z[0].split(':')
        return `${t[0]}:${t[1]}`
      } else {
        return null
      }
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    submit() {
      if (this.changeDetected === true) {
        let startDate = null
        let endDate = null

        if (this.formModel.startDay !== null) {
          if (this.formModel.startTime == null) {
            startDate = `${this.formModel.startDay} 09:00`
          } else {
            startDate = `${this.formModel.startDay} ${this.formModel.startTime}`
          }
        }

        if (this.formModel.startDay !== null) {
          if (this.formModel.endTime == null) {
            endDate = null
          } else {
            endDate = `${this.formModel.startDay} ${this.formModel.endTime}`
          }
        }

        const data = {
          ...this.formModel,
          startDate,
          endDate,
        }

        delete this.formModel.createdAt
        delete this.formModel.updatedAt

        if (this.sale?.id) {
          // Patching
          this.busy = true
          this.$api
            .put(`/sale/${this.sale?.id}`, {
              ...data,
              id: this.sale?.id,
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.setFormModel(apiResponse.data)
              this.$emit('saleUpdated', apiResponse.data)
              // this.$notify({
              //   type: 'success',
              //   title: 'Succès',
              //   message: 'Vente modifiée avec succès !',
              // })
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              this.changeDetected = false
            })
        } else {
          // Creating
          this.busy = true

          // Remove id
          delete data.id // To avoid error with the API when create (cannot deserialize with id = null - must be int)

          this.$api
            .post('/sale', data)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Vente créée avec succès !',
              })

              this.$emit('saleCreated', apiResponse.data)
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              this.changeDetected = false
            })
        }
      }
    },
    searchExistingSalePlace(queryString: string, cb: CallableFunction) {
      let results = [] as Record<string, string>[]
      const salePlaces = [] as Record<string, string>[]
      this.$api
        .get(`/sale/place`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((place: string) => {
            salePlaces.push({ value: place })
          })
          results = queryString ? salePlaces.filter(this.createFilter(queryString)) : salePlaces
        })
        .finally(() => {
          cb(results)
        })
    },
    searchExistingCollectArea(queryString: string, cb: CallableFunction) {
      let results = [] as Record<string, string>[]
      const collectAreas = [] as Record<string, string>[]
      this.$api
        .get(`/sale/collectareas`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((collectArea: string) => {
            collectAreas.push({ value: collectArea })
          })
          results = queryString ? collectAreas.filter(this.createFilter(queryString)) : collectAreas
        })
        .finally(() => {
          cb(results)
        })
    },
    createFilter(queryString: string) {
      return (str: Record<string, string>) => {
        return str.value?.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ?? null
      }
    },
    loadAuctioneers(isVisible: boolean) {
      if (isVisible) this.auctioneers = this.searchContacts('*', ['commissaire_justice'])
    },
    searchContacts(
      search?: string,
      category = null as string[] | null,
      minLength = 2
    ): Record<string, string | null>[] {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }

        this.busy = true
        let contacts = [] as Record<string, string | null>[]
        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
              category,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
              })
            }
          })
          .finally(() => {
            this.busy = false
          })

        return contacts
      } else {
        return []
      }
    },
  },
})
